/* Next */
//import Head from 'next/head'

/* Material */
import Typo from '@mui/material/Typography'
import Container from '@mui/material/Container'
import useStyles from './styles'

/* Components */
import BrLine from 'src/components/js_elements/BrLine'

import CarouselStory, { slideConfig as storySlide } from 'src/components/Carousels/Story'
import CarouselMood,  { slideConfig as moodSlide }  from 'src/components/Carousels/Mood'

import Products from 'src/components/ProductPreviewRenders/ProductRow'

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

const storySlides: storySlide[] = [
  {
    alt: 'Meraki',
    src: 'images/template/mood/meraki-mood.png',
    href: '/b/meraki/203',

    title: 'Meraki',

    subTitle: `
      Verleihen deinem Zuhause noch mehr Atmosphäre durch Meraki 
      Duftkerzen und Raumdüfte , die deine Sinne verwöhnen.
    `,
  },

  {
    alt: 'ph fragrances',
    src: 'images/template/mood/ph-fragrances-laundry-iris.png',
    href: '/b/ph-fragrances/205',

    title: 'ph fragrances',

    subTitle: `
      Verwöhnen Sie Ihre Sinne mit dem Duft des 
      außergewöhnlichen Waschmittel von ph fragrances.
    `,
  },
]

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/*
Beispiele für targets:
href: '/b/bjoerk_berries/190',
href: '/b/bondi_wash/189/m/tierpflege/1116',
href: '/brands',
*/
const moodSlides: moodSlide[] = [
  {
    src: 'images/template/story/greengate-pink.png',
    alt: 'Greengate - Happy Brunch Geschirr.',
    href: '/suche/Rose%20Alice/',
    position: 'top',
  },

  {
    src:'images/template/story/vossen-vegan-life.png',
    alt: 'Vossen - Vegan-Life Serie für unsere Umwelt.',
    href: '/suche/vegan%20life/',
    position: 'top',
  },

  {
    src: 'images/template/story/greengate-volcano.png',
    alt: 'Greengate - die neue Volcano Serie.',
    href: '/suche/volcano/',
    position: 'top',
  },

  {
    src: 'images/template/story/vossen-bademantel-ariel.png',
    alt: 'Vossen - Ariel Bademäntel sind Selbstliebe.',
    href: '/suche/ariel/',
    position: 'center',
  },
]

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

const Home = () => {
  const classes = useStyles()

  return <Container maxWidth='lg' className={classes.container}>

    <CarouselMood slideConfigs={moodSlides} />

    {/* --------------------------------------------------------------------- */}
    <hr />

    <Products
      display={['title', 'center', 'no-card']}
      size={6}
      keys={[
        '4073-45554-45562', // Cawö - Lifestyle Frottiertücher Uni 7007
        '3456-42209', // Joop - Classic Doubleface 1600-22
        '4920-53707', // Joop - Classic Cornflower 1611-22
        '7667-75111-77109', // Joop - Damen Bademantel Uni mit Kapuze 1652
        '7669-75126-75131', // Joop - Damen Bademantel Uni mit Schalkragen 1650
        '18944-110514', // Vossen - Coco Damen Bademantel - Hibiscus
      ]}
    />

    {/* --------------------------------------------------------------------- */}
    <hr />

    <CarouselStory slideConfigs={storySlides} />

    {/* --------------------------------------------------------------------- */}
    <hr />

    <Typo 
  className={classes.homeTextTitle}
  component='h2' variant='h4' color='inherit' gutterBottom
>
  <BrLine>Willkommen bei hellomom.de – Dein Zuhause für Stil und Komfort!</BrLine>
</Typo>

<Typo
  className={classes.homeTextTitle}
  component='h3' variant='h5' color='inherit' gutterBottom
>
  <BrLine>Dein Online-Shop für exklusive Home & Living Produkte</BrLine>
</Typo>

<Typo
  className={classes.homeText}
  component="p"
  variant="body2"
  color="inherit"
  paragraph
>
  Entdecke die Welt von <b>hellomom.de</b>, wo jedes Produkt liebevoll ausgewählt ist, 
  um Dein Zuhause in eine Oase der Gemütlichkeit und Eleganz zu verwandeln. Bei uns findest Du alles, 
  was Du brauchst, um Dein Heim stilvoll einzurichten und jeden Raum besonders zu machen.
</Typo>
<Typo
  className={classes.homeText}
  component="p"
  variant="body2"
  color="inherit"
  paragraph
>
  <b>Unser Sortiment umfasst:</b>
  <ul>
    <li><b>Badtextilien:</b> Verwandle Dein Badezimmer mit unseren luxuriösen Handtüchern, Bademänteln und weiteren Badaccessoires in eine Wohlfühlzone.</li>
    <li><b>Küchenutensilien:</b> Von edlem Geschirr bis hin zu praktischen und schönen Geschirrtüchern – bei uns ist für jeden Geschmack und Bedarf etwas dabei.</li>
  </ul>
</Typo>
<Typo
  className={classes.homeText}
  component="p"
  variant="body2"
  color="inherit"
  paragraph
>
  Unsere Produkte zeichnen sich durch hochwertige Materialien und anspruchsvolles Design aus. Wir legen großen Wert auf Nachhaltigkeit und bieten auch Artikel aus recycelten Materialien sowie von Marken, die sich aktiv für den Umweltschutz einsetzen.
</Typo>
<Typo
  className={classes.homeText}
  component="p"
  variant="body2"
  color="inherit"
  paragraph
>
  Bei <b>liebeszuhause.de</b> kannst Du ganz bequem von zu Hause aus stöbern und bestellen. Genieße unseren schnellen und zuverlässigen Versand direkt zu Dir nach Hause. Und falls etwas nicht ganz deinen Erwartungen entspricht, profitierst Du von unserem unkomplizierten Rückgaberecht.
</Typo>
<Typo
  className={classes.homeText}
  component="p"
  variant="body2"
  color="inherit"
  paragraph
>
  Lass Dich von unserer Auswahl inspirieren und finde genau das, was Du suchst, um Dein Zuhause noch schöner und komfortabler zu gestalten. Wir freuen uns darauf, Dein Heim mit unseren Produkten zu bereichern.
</Typo>

<hr />


<Products
  display={['title', 'center', 'no-card']}
  size={6}
  keys={[
    '21857-117746', // ALICE Oyster Blue Tasse
    '21842-117727', // ALICE Oyster Blue runder Krug
    '21834-117713', // Alice Oyster Blue Eierbecher
    '21827-117706', // ALICE Oyster Blue Schüssel
    '21824-117703', // Alice Oyster Blue Biscuit Plate
    '21851-117739', // Alice Oyster Blue Mini Latte cup
  ]}
/>

{/* --------------------------------------------------------------------- */}
  </Container>
}

export default Home